export default function Home()  {
    return (
        <body>
            <h2> Impressum </h2>
            <h3> Timo Kindl </h3>
            <p>Schreiberstraße 8</p>
            <p>90763, Fürth</p>
            <p>Tel.: 0911/9712029 | Wochentags erreichbar von 21:00 - 23:00</p>
            <p>E-Mail: kimotindllevis@gmail.com</p>
        </body>
    )
}