import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function Navbar() {
    return <nav className="nav">
        <Link to="/" className="site-title">Über Timo Kindl</Link>
<ul>
    <CustomLink to="/archaeodex">Archaeodex</CustomLink>
    <CustomLink to="/frankenadventure">Frankenadventure</CustomLink>
    <CustomLink to="/impressum">Impressum</CustomLink>
    <CustomLink to="/datenschutz">Datenschutz</CustomLink>
</ul>

    </nav>
}

function CustomLink({to, children, ...props})   {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end: true})
    return  (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>{children}</Link>
        </li>
    )
}