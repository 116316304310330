export default function Home()  {
    return (
        <body>
    <h1>Timo Kindl</h1>
    <p> Räder rollen, Mühlen mahlen,</p>
    <p> Im Westen gibt’s keine Spiralen</p>
    <p> Zahlen Zahlen Zahlen Zahlen</p>
    <p> So im Kreis geht’s immer weiter</p>
    <p> Hoppe Reiter, Hoppe Reiter, wenn du fällst dann schreite weiter.</p>
    <p> Bis die Zahlen mal versagen, kannst du warten viele Tage</p>
    <p> Nage Nägel, Fege Feuer</p>
    <p> Warte Jahrelang darauf,</p>
    <p> Hoff die Zahlen sterben aus.</p>
    <p> Doch die Zeiger zählen Zeit</p>
    <p> Bis in alle Ewigkeit</p>
    </body>
    )
}