import Navbar from './Navbar';
import "./styles.css"
import Home from './pages/Home';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import Archaeodex from './pages/Archaeodex';
import Hutten from './pages/Hutten';
import { Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

function App() {
  
  const location = useLocation();

  // Update state based on the location object
  useEffect(() => {
    // Get the path from the location object
    const path = location.pathname;

    // ***Get the search parameters from the location object
    //const searchParams = new URLSearchParams(location.search);
    //const searchValue = searchParams.get('search');

    // ***Get the hash from the location object
    //const hash = location.hash;

    // Update your app's state based on the path, search parameters, and/or hash
    // ...
  }, [location]);

  return (
  <>
    <Navbar/>
    <div className='container'>
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route path='/impressum' element={<Impressum />} />
      <Route path='/datenschutz' element={<Datenschutz />} />
      <Route path='/archaeodex' element={<Archaeodex />} />
      <Route path='/frankenadventure' element={<Hutten />} />
    </Routes>
    </div>
  </>
  );
}

export default App;
